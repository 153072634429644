import './style.scss';
import config from '../../../config';
import axios from 'axios';
import store from '../../../store';
import React from 'react';


class FormSubmission extends React.Component {
    formData: any = new FormData();

    initialState: any = {
        loading: false,
        showForm: true,
        name: '',
        icNumber: '',
        contactNumber: '',
        email: '',
        referralNumber: '',
        prizeLocation: '',
        receiptNumber: '',
        receiptImage: '',
        qna: '',
        locations: []
    }
    state: any = {
        ...this.initialState
    }

    locations = async () => {
        const res = await axios.get(`${config.API_URL}/aeon-x-visa-anniversary-2024-form-submission`, this.formData);
        return await res.data;
    }

    componentDidMount() {
        this.locations().then((data) => this.setState({ 'locations': data[0] }));
    }

    submitForm = async () => {
        if (this.state.name == null || this.state.name === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please insert name field to continue.'
            });
            return;
        }
        if (this.state.icNumber == null || this.state.icNumber === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please insert Ic number field to continue.'
            });
            return;
        }
        if (this.state.contactNumber == null || this.state.contactNumber === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please insert contact number field to continue.'
            });
            return;
        }
        if (this.state.contactNumber.substr(0, 2) !== "60") {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please fill in the contact number starts with 60.'
            });
            return;
        }
        //validatation email
        let emailVal = this.state.email;
        let emailValid = emailVal.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (emailVal !== '' && emailValid === null) {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please insert valid format email field to continue.'
            });
            return;
        }
        if (this.state.prizeLocation == null || this.state.prizeLocation === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please select preferred location for prize collection field to continue.'
            });
            return;
        }
        if (this.state.receiptNumber == null || this.state.receiptNumber === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please select receipt number field to continue.'
            });
            return;
        }
        if (this.state.receiptImage == null || this.state.receiptImage === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please select receipt image field to continue.'
            });
            return;
        }
        if (this.state.qna == null || this.state.qna === '') {
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Please select answer for Q&A field to continue.'
            });
            return;
        }

        this.setState({
            loading: true
        });

        //set formData
        this.formData.set('name', this.state.name);
        this.formData.set('ic_number', this.state.icNumber);
        this.formData.set('contact_number', this.state.contactNumber);
        this.formData.set('email', this.state.email);
        this.formData.set('location', this.state.prizeLocation);
        this.formData.set('receipt_number', this.state.receiptNumber);
        this.formData.set('receipt_image', this.state.receiptImage);
        this.formData.set('qna', this.state.qna);
        try {
            const res = await axios.post(`${config.API_URL}/aeon-x-visa-anniversary-2024-form-submission`, this.formData);
            const data = await res.data;
            // if (res.status) {
            //     // this.setState({ ...this.initialState })
            //     window.location.reload();
            // }
            if (data.error) {
                this.setState({
                    loading: false
                });
                store.dispatch({
                    type: 'notifications/showErrorNotification',
                    payload: data.error_message
                });
            } else {
                this.setState({
                    referralNumber: data.referral_number,
                    showForm: false
                });

                store.dispatch({
                    type: 'notifications/showSuccessNotification',
                    // payload: 'Thank you for Participating! Submit more to increase your chance of winning!',
                    payload: 'Thank you for submission. Submit more to increase your chance of winning!',
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            });
            store.dispatch({
                type: 'notifications/showErrorNotification',
                payload: 'Sorry, something went wrong. Please check your internet connection and try again later.'
            });
        }
    }

    render() {

        const generateLocations: any = () => {
            return this.state.locations.map((data: string) => (
                <option value={data} key={data}>{data}</option>
            ))
        }

        return (
            <div>
                <div className="min-h-screen px-3 py-4 grid justify-items-center" style={{ backgroundColor: '#fff' }}>
                    <div className="flex justify-between w-full px-10 align-middle">
                        <div>
                            <img src={require('./assets/aeon.png').default} alt="aeon" style={{
                                display: 'block',
                                width: '50%',
                                marginTop: '10px',
                                height: 'auto',
                                maxWidth: 250,
                            }} />
                        </div>
                        <h1 className="text-center text-base font-medium" style={{ marginBottom: '15px', fontWeight: 'bold', fontSize: '20px'  }}>2024 AEON Visa Tap & Win Contest</h1>                        
                        <div className='flex flex-col items-center justify-center'>
                            <img src={require('./assets/visa-logo.png').default} alt="visa" style={{
                                display: 'block',
                                width: '50%',
                                height: 'auto',
                                maxWidth: 250,
                            }} />
                        </div>
                    </div>
                    <h1 className="text-center text-base font-medium" style={{ marginBottom: '15px' }}>Tap to Pay a minimum RM100 with your Visa card from <span>1 – 30 June 2024</span> <br /> for a chance to win an iPhone 15 or RM100 AEON Gift Vouchers. <br />Fill in your details at below columns for participation!</h1>
                    <div className="container max-w-lg">
                        <div className="bg-white w-full rounded-xl px-4 py-4">
                            <div className="border border-black px-4 rounded-xl py-2 mb-6">
                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">Full Name as per NRIC <sup style={{ color: 'red' }}>*</sup></label>
                                    <input
                                        value={this.state.name}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.setState({
                                                name: value
                                            });
                                        }} type="text"
                                        required
                                        className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none"
                                        placeholder="Enter Name" />
                                </div>

                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">IC Number <sup style={{ color: 'red' }}>*</sup></label>
                                    <input
                                        value={this.state.icNumber}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.setState({
                                                icNumber: value
                                            });
                                        }} type="number"
                                        className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none"
                                        placeholder="Enter IC Number" />
                                </div>
                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">Contact Number <sup style={{ color: 'red' }}>*</sup></label>
                                    <input
                                        value={this.state.contactNumber}
                                        onChange={(event) => {
                                            this.setState({
                                                contactNumber: event.target.value
                                            });
                                        }} type="number"
                                        className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none"
                                        placeholder="Enter Contact Number" />
                                </div>
                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">Email</label>
                                    <input
                                        value={this.state.email}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.setState({
                                                email: value
                                            });
                                        }} type="email"
                                        required
                                        className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none"
                                        placeholder="Enter Email" />
                                </div>
                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">Preferred Location for Prize Collection <sup style={{ color: 'red' }}>*</sup></label>
                                    <select
                                        value={this.state.prizeLocation}
                                        onChange={async (event) => {
                                            this.setState({
                                                prizeLocation: event.target.value
                                            });
                                        }} required className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none bg-white">
                                        <option value="">Select Prize Location</option>
                                        {generateLocations()}
                                    </select>
                                </div>
                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">Purchase Receipt Number <sup style={{ color: 'red' }}>*</sup></label>
                                    <input
                                        value={this.state.receiptNumber}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.setState({
                                                receiptNumber: value
                                            });
                                        }} type="text"
                                        required
                                        className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none"
                                        placeholder="Enter Purchase Receipt Number" />
                                </div>
                                <div className="grid mb-2">
                                    <label className="block">
                                        <span className="pl-1 pb-0 text-sm text-black w-full mb-2">Upload Photo of Purchase Receipt & Credit Card Terminal Slip <sup style={{ color: 'red' }}>*</sup></span>
                                        <input
                                            // value={this.state.receiptImage}
                                            onChange={(event) => {
                                                this.setState({
                                                    receiptImage: event.target.files ? event.target.files[0] : null
                                                });
                                            }} type="file" className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none" />
                                    </label>
                                </div>
                                <div className="grid mb-2">
                                    <label className="pl-1 pb-0 text-sm text-black w-full mb-2">Is Visa the official partner of 2024 AEON Visa Tap & Win Contest? <sup style={{ color: 'red' }}>*</sup></label>
                                    <select
                                        value={this.state.qna}
                                        onChange={async (event) => {
                                            this.setState({
                                                qna: event.target.value
                                            });
                                        }} required className="pl-1 text-sm py-2 w-full rounded border border-gray-400 focus:ring focus:ring-pink-300 focus:outline-none bg-white">
                                        <option value="">Select Answer</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-5 flex justify-center content-center">
                                <button className="bg-blue-500 w-4/6 rounded-sm py-1.5 px-1 text-white" onClick={this.submitForm}>
                                    {!this.state.loading && <span>Submit</span>}
                                    {this.state.loading && <span>Loading...</span>}
                                </button>
                            </div>
                            {/* <div className="mb-2 mt-2 flex justify-center content-center">
                                    <a href="https://www.aeonretail.com.my/promotion/starewards2022/" className="mx-auto">Terms & Condition apply</a>
                                </div> */}
                            {/* <div className="justify-center content-center text-center" style={{fontSize: '12px'}}>
                                <p>*Terms & Conditions apply.</p>
                                <p>Cash credit prizes will be delivered as cash crediting into AEON Member Plus Visa Card. All winners must ensure they have sign up / register / have an active AEON Member Plus Visa Card.</p>
                            </div> */}
                        </div>
                        <h3 className="text-center text-base font-small">*Terms and Conditions apply. </h3>
                        <h3 className='text-center text-base font-small'>
                            The iPhone 15 given will be randomized color subject to Organizers' final decision, 
                            any request or appeal to specific color(s)/ specification(s)/ design(s) will not
                            be entertained.
                        </h3>
                            <br />
                            <h3 className="text-center text-base font-small" style={{ marginBottom: '15px' }}>
                                By submitting to participate in this Contest, you agree and
                                consent to use of your name and photograph by the Organizer
                                for advertising and publicity purposes.
                            </h3>
                    </div>
                </div>
                {!this.state.showForm &&
                    <div className="h-full px-3 py-4 flex justify-center flex-col fixed top-0 w-full" style={{ backgroundColor: 'rgba(0,0,0, 0.5)', backdropFilter: 'blur(5px)' }}>
                        <div className="bg-white w-1/3 h-auto rounded-xl px-4 py-4 mx-auto">
                            <div className="flex justify-between flex-col-reverse align-top">
                                <h1 className="text-center text-2xl mb-4 font-semibold">Thank you for Participating!</h1>
                                <div className="text-right">
                                    <button onClick={() => window.location.reload()}>X</button>
                                </div>
                            </div>
                            <h3 className='text-center'>Submit more to increase your chance of winning !</h3>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default FormSubmission