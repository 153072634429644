import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import AppErrorNotification from './components/error-notification.component';
import AppSuccessNotification from './components/success-notification.component';
import Routes from './Routes';
import store from './store';

class App extends React.Component {
    unsubscribe: any;
    state: any = {
        notifications: {}
    };

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            const newState = store.getState();
            this.setState({
                notifications: newState.notifications
            });
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe !== null) {
            this.unsubscribe();
        }
    }

    render() {
        return (
            <div>
                <Router>
                    <Routes />
                </Router>
                <CSSTransition
                    in={this.state.notifications.showSuccessNotification}
                    timeout={800}
                    classNames="app-notification-transition"
                    unmountOnExit
                >
                    <AppSuccessNotification />
                </CSSTransition>
                <CSSTransition
                    in={this.state.notifications.showErrorNotification}
                    timeout={800}
                    classNames="app-notification-transition"
                    unmountOnExit
                >
                    <AppErrorNotification />
                </CSSTransition>
            </div>
        );
    }
}

export default App;
