import React from 'react';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router';
import NotFound from './pages/not-found/not-found-page';
import FormSubmission from './pages/campaigns/forms/FormSubmission';

class Routes extends React.Component<RouteComponentProps, any> {
    public render() {
        return (
            <Switch>
                
                {/* FORM SUBMISSION*/}
                <Route exact path="/" render={props => <FormSubmission {...props} />} />

                <Route path="*" render={props => <NotFound {...props} />} />
            </Switch>
        );
    }
}

export default withRouter(Routes);
