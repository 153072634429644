import React from 'react';

class NotFound extends React.Component {
    render() {
        return(
            <div className="text-center flex h-screen w-100 items-center justify-center">
                <div>
                    <h1 className="text-bold text-2xl">404 Not Found</h1>
                    <p className="mt-3 text-gray-400">Sorry, we couldn't find that link.</p>
                </div>
            </div>
        );
    }
}

export default NotFound;
