import React from 'react';
import store from '../store';

class AppErrorNotification extends React.Component {
    message: string = store.getState().notifications.notificationMessage;

    closeNotification = () => {
        store.dispatch({
            type: 'notifications/closeNotification'
        });
    }

    render() {
        return(
            <div className='fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end' style={{
                zIndex: 99
            }}>
                <div className='max-w-sm w-full bg-red-500 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                    <div className='p-4'>
                        <div className='flex items-center'>
                            <div className='ml-3 w-0 flex-1 pt-0.5'>
                                <p className='text-sm font-medium text-white'>
                                    Error
                                </p>
                                <p className="mt-1 text-sm text-white">
                                    {this.message}
                                </p>
                            </div>
                            <p className="text-white" onClick={this.closeNotification} > 
                                Ok
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppErrorNotification;
