const initialState = {
    showErrorNotification: false,
    showSuccessNotification: false,
    notificationMessage: ''
};

export default function notificationsReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'notifications/showErrorNotification':
            return {
                ...state,
                showErrorNotification: true,
                notificationMessage: action.payload
            };

        case 'notifications/showSuccessNotification':
            return {
                ...state,
                showSuccessNotification: true,
                notificationMessage: action.payload
            };

        case 'notifications/closeNotification':
            return {...state, showErrorNotification: false, showSuccessNotification: false};

        default:
            return state;
    }
}
